<template>
  <div>
    <b-card>

      <!-- form -->
      <b-form
        class="mt-2"
        @submit.stop.prevent="onSubmit"
      >
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Create Kiosk
              </h3>
            </div>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Kiosk Name"
              label-for="kiosk-name"
              class="mb-2"
            >
              <b-form-input
                id="kiosk-name"
                v-model="kioskData.name"
                :state="validateState('name')"
                @input="slugHandler"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Slug"
              label-for="kiosk-slug"
              class="mb-2"
            >
              <b-form-input
                id="kiosk-slug"
                v-model="kioskData.slug"
                :state="validateState('slug')"
              />
              <span
                v-if="!slugIsUnique"
                class="danger"
              >Slug must be unique.
              </span>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Language"
              label-for="kiosk-language"
              class="mb-2"
            >
              <b-form-select
                id="kiosk-language"
                v-model="kioskData.language"
                :options="languageList"
                :placeholder="'Select Language'"
                :state="validateState('language')"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group
              label="Payment Processor"
              label-for="kiosk-payment-processor"
              class="mb-2"
            >
              <b-form-select
                id="kiosk-payment-processor"
                v-model="kioskData.paymentProcessor"
                :options="paymentProcessorOptions"
                :placeholder="'Select Payment Processor'"
                :state="validateState('paymentProcessor')"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group
              label="Currency"
              label-for="kiosk-currency"
              class="mb-2"
            >
              <b-form-select
                id="kiosk-currency"
                v-model="kioskData.currencyId"
                :options="currencyOptions"
                :placeholder="'Select Currency'"
                :state="validateState('currencyId')"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            md="6"
            :class="{'d-none': selectedLayout == 'carrot' ? true : false }"
          >
            <b-form-group
              label="Donation Options"
              label-for="kiosk-donation-options"
              class="mb-2"
            >
              <b-form-tags
                v-model="kioskData.donationOptions"
                input-id="tags-basic"
                :tag-validator="tagValidator"
                class="mb-2"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Campaign ID"
              label-for="campaign_id"
              class="mb-2"
            >
              <b-form-input
                id="campaign_id"
                v-model="kioskData.campaign_id"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Designation"
              label-for="designation"
              class="mb-2"
            >
              <b-form-input
                id="designation"
                v-model="kioskData.designation"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Donation Sheet Url"
              label-for="donation_sheet_url"
              class="mb-2"
            >
              <b-form-input
                id="donation_sheet_url"
                v-model="kioskData.donation_sheet_url"
                :state="validateState('donation_sheet_url')"
              />
              <span
                v-if="!isValidUrl"
                class="danger"
                >
                Donation Sheet URL is invalid
              </span>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              v-model="kioskData.has_group"
              name="check-button"
              switch
            >
              Has Group
            </b-form-checkbox>
          </b-col>

          <b-col md="6">
            <b-form-group
              v-if="kioskData.has_group==true"
              label="SheetDB Key"
              label-for="sheetdb_key"
              class="mb-2"
            >
              <b-form-input
                id="sheetdb_key"
                v-model="kioskData.sheetdb_key"
              />
              <span
                v-if="sheetFieldsRequire"
                class="danger"
              >Field is require when kiosk has group.
              </span>
            </b-form-group>
          </b-col>
          <b-col md="6">
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="kioskData.has_group==true"
              label="Sheet Name"
              label-for="sheet_name"
              class="mb-2"
            >
              <b-form-input
                id="sheet_name"
                v-model="kioskData.sheet_name"
              />
              <span
                v-if="sheetFieldsRequire"
                class="danger"
              >Field is require when kiosk has group.
              </span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Payment Options
              </h3>
            </div>
          </b-col>
          <div class="card">
            <div class="card-body">
              <div
                v-for="input,index in kioskData.paymentOptions"
                :key="index"
              >
                <h3>Option {{ index+1 }}</h3>
                <div class="row">
                  <b-col md="2">
                    <b-form-group
                      label="Payment Processor"
                      label-for="kiosk-payment-processor"
                      class="mb-2"
                    >
                      <b-form-select
                        id="kiosk-payment-processor"
                        v-model="input.paymentProcessor"
                        :options="paymentProcessorOptions"
                        :placeholder="'Select Payment Processor'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      label="Currency"
                      label-for="kiosk-currency"
                      class="mb-2"
                    >
                      <b-form-select
                        id="kiosk-currency"
                        v-model="input.currencyId"
                        :options="currencyOptions"
                        :placeholder="'Select Currency'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-if="input.paymentProcessor=='ba3f5e65-14a7-3112-8662-005cb39f372a'"
                      label="Supplier Url"
                      label-for="supplier url"
                      class="mb-2"
                    >
                      <b-form-input
                        id="supplier"
                        v-model="input.suplier_url"
                      />
                    </b-form-group>
                    <!-- Stripe input -->
                    <b-form-group
                      v-if="input.paymentProcessor=='13a57430-5aa8-3ca2-82f1-28f322060a11'"
                      label="Publish Key"
                      label-for="publish key"
                      class="mb-2"
                    >
                      <b-form-input
                        id="publish_key"
                        v-model="input.publish_key"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-if="input.paymentProcessor=='ba3f5e65-14a7-3112-8662-005cb39f372a'"
                      label="Supplier"
                      label-for="supplier"
                      class="mb-2"
                    >
                      <b-form-input
                        id="supplier"
                        v-model="input.suplier"
                      />
                    </b-form-group>
                    <!-- Stripe input -->
                    <b-form-group
                      v-if="input.paymentProcessor=='13a57430-5aa8-3ca2-82f1-28f322060a11'"
                      label="Secret Key"
                      label-for="secret key"
                      class="mb-2"
                    >
                      <b-form-input
                        id="secret_key"
                        v-model="input.secret_key"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-if="input.paymentProcessor=='ba3f5e65-14a7-3112-8662-005cb39f372a'"
                      label="Password"
                      label-for="password"
                      class="mb-2"
                    >
                      <b-form-input
                        id="password"
                        v-model="input.suplier_pw"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-button
                      variant="danger"
                      class="mt-2 mr-1 p-0"
                      size="sm"
                      @click.prevent="removeField(index, kioskData.paymentOptions)"
                    >
                      <feather-icon
                        icon="MinusIcon"
                        size="18"
                      />
                    </b-button>
                    <b-button
                      variant="success"
                      class="mt-2 p-0"
                      size="sm"
                      @click.prevent="addField(kioskData.paymentOptions)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </div>
              </div>
            </div>
          </div>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Layout Settings
              </h3>
            </div>
          </b-col>
          <b-col md="6">
            <div class="border rounded p-2 d-flex flex-column align-items-center justify-content-center">
              <b-form-radio
                v-model="selectedLayout"
                name="some-radios"
                value="blueberry"
              >
                Layout 1
              </b-form-radio>
              <div class="mt-3">
                <img
                  :src="require('@/assets/images/layouts/layout-1.png')"
                  alt="blueberry"
                  class="layout-img"
                  :class="{isActive: selectedLayout == 'blueberry' ? true : false }"
                >
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="border rounded mb-3 p-2 d-flex flex-column align-items-center justify-content-center">
              <b-form-radio
                v-model="selectedLayout"
                name="some-radios"
                value="carrot"
              >
                Layout 2
              </b-form-radio>
              <div class="mt-3">
                <img
                  :src="require('@/assets/images/layouts/layout-2.png')"
                  alt="carrot"
                  class="layout-img"
                  :class="{isActive: selectedLayout == 'carrot' ? true : false }"
                >
              </div>
            </div>
          </b-col>

          <b-col md="6">
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside vertical-align="center">
                <b-img
                  :src="kioskData.imageLogo"
                  width="170"
                  blank-color="#ccc"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <b-form-group
                  label="Logo Image"
                  label-for="logo-img"
                  class="mb-2"
                >
                  <b-form-file
                    v-model="fileLogo"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose FIle"
                    drop-placeholder="Drop file here..."
                    class="mt-2"
                    :state="validateState('imageLogo')"
                    @change="(event)=>uploadFileLogo(event)"
                  />
                </b-form-group>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="6">
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside vertical-align="center">
                <b-img
                  v-if="checkFormat !== 'mp4'"
                  :src="kioskData.imageBackground"
                  width="170"
                  blank-color="#ccc"
                  class="rounded mr-2 mb-1 mb-md-0 pl-1"
                />
              </b-media-aside>
              <b-embed
                v-if="checkFormat === 'mp4'"
                vertical-align="center"
                class="media-aside align-self-center"
                type="video"
                aspect="4by3"
                controls
                allowfullscreen
              >
                <source
                  :src="kioskData.imageBackground"
                  type="video/mp4"
                >
              </b-embed>
              <b-media-body>
                <b-form-group
                  label="Background Image or Video"
                  label-for="background-img"
                  class="mb-2"
                >
                  <b-form-file
                    v-model="fileBackground"
                    accept=".jpg, .png, .gif, .mp4"
                    placeholder="Choose FIle"
                    drop-placeholder="Drop file here..."
                    class="mt-2"
                    :state="validateState('imageBackground')"
                    @change="(event)=>uploadFileBackground(event)"
                  />
                </b-form-group>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2 mt-3">
              <h3 class="mb-0 font-weight-normal">
                Email Settings
              </h3>
            </div>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="From"
              label-for="email-from"
              class="mb-2"
            >
              <b-form-input
                id="email-from"
                v-model="kioskData.from"
                :state="validateState('from')"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="From Email"
              label-for="email-from-email"
              class="mb-2"
            >
              <b-form-input
                id="email-from-email"
                v-model="kioskData.fromEmail"
                type="email"
                :state="validateState('fromEmail')"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Subject"
              label-for="email-subject"
              class="mb-3"
            >
              <b-form-input
                id="email-from-subject"
                v-model="kioskData.subject"
                :state="validateState('subject')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="email-content"
              class="mb-3"
              :class="{'is-invalid': $v.kioskData.content.$dirty && !$v.kioskData.content.required }"
            >
              <editor
                id="email-content"
                v-model="kioskData.content"
                :api-key="tinyAPI"
                :init="{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | code | removeformat | help'
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <p>Available placeholders for body</p>
            <b-card
              class="border rounded p-2 "
            >
              <b-card-text>
                %%logo%%<br>
                %%first_name%%<br>
                %%last_name%%<br>
                %%donation_amount%%<br>
                %%phone%%<br>
                %%email%%<br>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Create Kiosk
            </b-button>
            <b-button
              variant="outline-secondary"
              :to="{ name: 'admin-kiosks' }"
            >
              Discard
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BCard,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BFormRadio,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BFormFile,
  BFormSelect,
  BEmbed,
} from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'
import Ripple from 'vue-ripple-directive'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

const regex = /https?:\/\/[^\s]+/g

export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    BFormRadio,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormFile,
    BFormSelect,
    BEmbed,
    // eslint-disable-next-line vue/no-unused-components
    editor: Editor,
  },
  directives: {
    Ripple,
  },
  mixins: [
    validationMixin,
  ],
  data() {
    return {
      kiosks: null,
      slugIsUnique: true,
      sheetFieldsRequire: false,
      isValidUrl: false,
      kioskData: {
        name: null,
        slug: null,
        language: null,
        donation_sheet_url: '',
        campaign_id: '',
        designation: '',
        has_group: false,
        sheetdb_key: '',
        sheet_name: '',
        // paymentProcessor: null,
        imageLogo: null,
        imageBackground: null,
        donationOptions: [],
        paymentOptions: [{
          paymentProcessor: null, currencyId: null, suplier_url: null, suplier: null, suplier_pw: null, publish_key: null, secret_key: null,
        }],
        // currencyId: null,
        from: null,
        fromEmail: null,
        subject: null,
        content: null,
      },
      languageList: [],
      currencyOptions: [],
      paymentProcessorOptions: [],
      templatesData: [],
      selectedLayout: 'blueberry',
      snowOption: {
        theme: 'snow',
      },
      fileLogo: null,
      fileBackground: null,
      tinyAPI: process.env.VUE_APP_TINYMCE,
    }
  },
  computed: {
    checkFormat() {
      if (this.kioskData.imageBackground && this.kioskData.imageBackground.indexOf('.mp4') !== -1) {
        return 'mp4'
      }
      return ''
    },
  },
  watch: {
    'kioskData.slug': function (newVal) {
      if (!newVal) return
      if (this.kiosks.find(kiosk => kiosk.slug === this.kioskData.slug)) {
        // console.log('isExist')
        this.slugIsUnique = false
      } else {
        this.slugIsUnique = true
      }
    },
    'kioskData.has_group': function (toggleVal) {
      if (toggleVal && (this.kioskData.sheetdb_key === '' || this.kioskData.sheet_name === '')) {
        this.sheetFieldsRequire = true
      } else {
        this.sheetFieldsRequire = false
      }
    },
    'kioskData.donation_sheet_url': function (urlVal) {
      this.isValidUrl = urlVal.match(regex)
      // this.isValidUrl = regex.test(urlVal)
    },
  },
  mounted() {
    this.getCurrencies()
    this.getLanguages()
    this.getPayments()
    this.getTemplates()
  },
  async created() {
    await this.getKiosks()
  },
  validations: {
    kioskData: {
      slug: {
        required,
      },
      name: {
        required,
        minLength: minLength(1),
      },
      from: {
        required,
      },
      fromEmail: {
        required,
        email,
      },
      subject: {
        required,
      },
      language: {
        required,
      },
      donation_sheet_url: {
        required,
      },
      // paymentProcessor: {
      //   required,
      // },
      // currencyId: {
      //   required,
      // },
      // supplier: {
      //   required,
      // },
      // password: {
      //   required,
      // },
      imageLogo: {
        required,
      },
      imageBackground: {
        required,
      },
      content: {
        required,
      },
    },
  },
  methods: {
    async getKiosks() {
      axios
        .get('/auth/kiosks')
        .then(response => {
          this.kiosks = response.data.data
        })
    },
    getCurrencies() {
      axios
        .get('/auth/currencies')
        .then(response => {
          this.currencyOptions = response.data.data.map(d => (
            { value: d.id, text: d.name }
          ))
        })
    },
    getLanguages() {
      axios
        .get('/auth/language')
        .then(response => {
          this.languageList = response.data.data.map(d => (
            { value: d.id, text: d.name }
          ))
        })
    },
    getPayments() {
      axios
        .get('/auth/payment')
        .then(response => {
          this.paymentProcessorOptions = response.data.data.map(d => (
            { value: d.id, text: d.name }
          ))
        })
    },
    getTemplates() {
      axios
        .get('/auth/templates')
        .then(response => {
          this.templatesData = response.data.data
        })
    },
    getTemplateId(name) {
      let result = null
      this.templatesData.forEach(d => {
        if (d.name === name) result = d.id
      })
      return result
    },
    uploadFileLogo(e) {
      const file = e.target.files[0]
      const path = 'logo'
      const formData = new FormData()
      formData.append('file', file)
      axios
        .post(`/file/upload/${path}`, formData, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.kioskData.imageLogo = process.env.VUE_APP_ROOT_STORAGE + response.data.file_path
        })
    },
    uploadFileBackground(e) {
      const file = e.target.files[0]
      const path = 'background'
      const formData = new FormData()
      formData.append('file', file)
      axios
        .post(`/file/upload/${path}`, formData, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.kioskData.imageBackground = process.env.VUE_APP_ROOT_STORAGE + response.data.file_path
        })
    },
    slugHandler(e) {
      this.kioskData.slug = e.toLowerCase().replace(/\s+/g, '-')
    },
    tagValidator(tag) {
      return (Number(tag) > 0)
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.kioskData[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // Slug must be unique
      if (this.kiosks.find(kiosk => kiosk.slug === this.kioskData.slug)) {
        this.slugIsUnique = false
        return
      }
      if (this.kioskData.has_group && (this.kioskData.sheetdb_key === '' || this.kioskData.sheet_name === '')) {
        this.sheetFieldsRequire = true
        return
      }
      this.$v.kioskData.$touch()
      if (this.$v.kioskData.$anyError) {
        // console.log(this.$v.kioskData)
        return
      }

      this.isValidUrl = this.kioskData.donation_sheet_url.match(regex)
      // this.isValidUrl = regex.test(this.kioskData.donation_sheet_url)

      if (!this.isValidUrl) {
        return
      }
      const data = {
        title: this.kioskData.name,
        slug: this.kioskData.slug,
        url: this.kioskData.slug,
        logo: this.kioskData.imageLogo,
        background_image: this.kioskData.imageBackground,
        language_id: this.kioskData.language,
        donation_sheet_url: this.kioskData.donation_sheet_url,
        campaign_id: this.kioskData.campaign_id,
        designation: this.kioskData.designation,
        has_group: this.kioskData.has_group === true ? 1 : 0,
        sheetdb_key: this.kioskData.sheetdb_key,
        sheet_name: this.kioskData.sheet_name,
        donation_options: JSON.stringify(this.kioskData.donationOptions),
        // payment_id: this.kioskData.paymentProcessor,
        // currency_id: this.kioskData.currencyId,
        payment_options: this.kioskData.paymentOptions,
        template_id: this.getTemplateId(this.selectedLayout),
        email_template: {
          from_email: this.kioskData.fromEmail,
          from_name: this.kioskData.from,
          subject: this.kioskData.subject,
          body: this.kioskData.content,
        },
      }
      axios
        .post('/auth/kiosks', data)
        .then(() => this.$router.push({ name: 'admin-kiosks' }))
    },
    addField(paymentOptions) {
      paymentOptions.push({})
    },
    removeField(index, paymentOptions) {
      if (paymentOptions.length > 1) {
        paymentOptions.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.layout-img {
  max-width: 100%;
  opacity: 0.5;
}
.isActive {
  opacity: 1;
}
.custom-file-label::after {
  background-color: #7367F0;
  color: #fff;
}
.embed-responsive {
  width: 170px!important;
  height: 106px!important;
  margin-right: 1rem;
}
.is-invalid {
  .tox-tinymce {
    border-color: #ea5455!important;
  }
}
.danger{
  position: absolute;
  font-size: 12px;
  color: #ea5455;
}
</style>
